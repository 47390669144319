import React, { useState } from "react";
import QRCodeContext from "./QRCodeContext";

function QRCodeProvider({ children }) {
  const [url, setUrl] = useState("");
  const [qrCode, setQRCode] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const handleChange = (event) => {
    setUrl(event.target.value);
  };

  const handleGenerate = () => {
    setQRCode(url);
    setImageLoaded(true);
  };

  const context = {
    url,
    qrCode,
    imageLoaded,
    handleChange,
    handleGenerate,
  };

  return (
    <QRCodeContext.Provider value={context}>{children}</QRCodeContext.Provider>
  );
}

export default QRCodeProvider;
