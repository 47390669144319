import React from "react";
import styled from "styled-components";

const TitleContainer = styled.div`
  h1 {
    font-family: "Droid Sans", sans-serif;
    font-weight: 500;
    color: #333;
    font-size: 24px;
  }
`;

const Title = () => (
  <TitleContainer>
    <h1>Meu QR Code</h1>
  </TitleContainer>
);

export default Title;
