import styled from "styled-components";
import { Switch } from "antd";
import media from "styled-media-query";

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;
export const FullScreenDiv = styled.div``;
export const Card = styled.div``;

export const MainCardStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const LeftSection = styled.div`
  border: 1px solid red;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const RightSection = styled.div`
  border: 1px solid red;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const ImageContainer = styled.div`
  display: flex;
  height: 250px;
  width: 250px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

export const Button = styled.button`
  border: 1px solid #ccc;
  font-size: 16px;
  padding: 8px 12px;
  border: 1px solid #333;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  width: 100px;

  &:first-child {
    margin-right: 10px;
  }
`;
export const AdBannerStyle = styled.div`
  width: 100%;
  height: 1px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const HeaderStyle = styled.header`
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  ${media.lessThan("small")`
  flex-direction: column;
`}
`;

export const Logo = styled.img`
  height: 32px;
  padding: 10px;
`;

export const SiteName = styled.h1`
  font-size: 18px;
  margin: 0;
`;

export const DarkModeToggle = styled(Switch)`
  margin-left: 16px;
`;

export const Instructions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100 %;
  h1 {
    font-family: "Droid Sans", sans-serif;
    font-weight: 500;
    color: #333;
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    ${media.lessThan("small")`
    font-size: 20px;

  `}
  }
  h3 {
    font-family: "Droid Sans", sans-serif;
    font-weight: 500;
    color: #333;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    ${media.lessThan("small")`
    font-size: 14px;
  `}
  }
`;

export const Input = styled.input`
  width: 95%;
  height: 48px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  ${media.lessThan("small")`
  width: 98%;
`}
`;

export const GenerateButton = styled.button`
  width: 75%;
  margin-left: 5px;
  height: 48px;
  background-color: #00bcd4;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  ${media.lessThan("small")`
  width: 100%;
  margin-left: 0px;
  margin-top: 2px;
`}
`;

export const QRContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DownloadButton = styled.button`
  width: 95%;
  height: 48px;
  background-color: #00bcd4;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export const DownloadIcon = styled.i`
  font-size: 24px;
`;
export const AdSidebarStyle = styled.div`
  width: 1px;
  height: 100%;
  background-color: #fff;
  border: 1px solid #ccc;

  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
`;
export const FormStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  ${media.lessThan("small")`
  flex-direction: column;
  width: 100%;
`}
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;
