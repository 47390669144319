import Header from "./Header";
import MainCard from "./MainCard";
import QRCodeProvider from "./QRCodeProvider";

const App = () => {
  return (
    <QRCodeProvider>
      <Header />
      <MainCard />
    </QRCodeProvider>
  );
};
export default App;
