import { useContext } from "react";
import {
  HeaderStyle,
  Logo,
  Input,
  GenerateButton,
  FormStyle,
  LogoContainer,
} from "./styles";
import QRCodeContext from "./QRCodeContext";
import logo from "./logo.png";
import Title from "./Title";
const Header = () => {
  const { handleChange, handleGenerate, url } = useContext(QRCodeContext);

  return (
    <HeaderStyle>
      <LogoContainer>
        <Logo src={logo} alt="Logo do site meu QR Code" />
        <Title />
      </LogoContainer>
      <FormStyle>
        <Input
          type="text"
          value={url}
          onChange={handleChange}
          placeholder="Insira a URL aqui"
        />
        <GenerateButton onClick={handleGenerate}>Gerar QR Code</GenerateButton>
      </FormStyle>
    </HeaderStyle>
  );
};

export default Header;
