import { createGlobalStyle, css } from 'styled-components';
import '@fortawesome/fontawesome-free/css/all.css';

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-image: url(bg.jpg);
    background-size: cover;
  }

  ${({ darkMode }) =>
    darkMode
      ? css`
          background-color: #000;
          color: #fff;
        `
      : css`
          background-color: #fff;
          color: #000;
        `}
`;

export default GlobalStyle;