import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import {
  MainCardStyle,
  Instructions,
  QRContainer,
  DownloadButton,
  DownloadIcon,
} from "./styles";

import QRCode from "qrcode.react";
import QRCodeContext from "./QRCodeContext";
import qrcode from "./qr-code.png";

const MainCard = () => {
  const { qrCode, imageLoaded } = useContext(QRCodeContext);

  const handleDownloadPNG = () => {
    const canvas = document.querySelector("#qr-code");
    const pngUrl = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.download = "qr-code.png";
    link.href = pngUrl;
    link.click();
  };
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <MainCardStyle>
      <Instructions>
        <h1>Transforme URL's em QR Code</h1>
        <h3>Use a caixa de texto acima e clique em download</h3>
      </Instructions>
      <QRContainer>
        {qrCode && imageLoaded && (
          <QRCode
            id="qr-code"
            value={qrCode}
            fgColor="#000"
            bgColor="#fff"
            size={isMobile ? 200 : 300}
            includeMargin={false}
            renderAs="canvas"
          />
        )}
        {!imageLoaded && <img src={qrcode} alt="pre-loaded" />}
        <DownloadButton onClick={handleDownloadPNG}>
          <DownloadIcon />
          Download
        </DownloadButton>
      </QRContainer>
    </MainCardStyle>
  );
};

export default MainCard;
